<template>
  <v-container grid-list-xs v-if="!loading && schedule">
    <v-row>
      <v-col cols="12" sm="9">
        <h3>Schedule for {{ schedule.date }}</h3>
      </v-col>
      <v-col cols="12" sm="2">
        <router-link to="/schedule/calendar">View Schedules</router-link>
      </v-col>
      <v-col cols="1">
        <v-menu transition="scale-transition" origin="right top" bottom left>
          <v-btn class="ma-0" icon slot="activator">
            <v-icon>more_vert</v-icon>
          </v-btn>
          <v-list class="app-card-action">
            <v-list-item @click.stop="deleteSchedule">
              <v-icon color="red" class="mr-3">remove</v-icon>
              <span>{{ $t('Common.delete') }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mr-5" cols="12" md="3">
        <v-text-field v-model="schedule.title" label="Title"></v-text-field>
      </v-col>
      <v-col cols="12" md="8">
        <v-text-field
          v-model="schedule.description"
          label="Description"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mr-5" cols="12" md="3">
        <v-checkbox
          label="Is schedule Active?"
          v-model="schedule.isActive"
          @change="activeStatus"
          persistent-hint
          hint="Enable or disable an schedule"
        ></v-checkbox>
      </v-col>
      <v-col class="mr-5" cols="12" md="3">
        <v-checkbox
          persistent-hint
          :disabled="!schedule.isActive"
          hint="Can customers see this schedule?"
          label="Public?"
          v-model="schedule.isPublic"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="4">
        <v-checkbox
          label="Allow Third-party delivery"
          persistent-hint
          v-model="schedule.allowThirdPartyDelivery"
          hint="3rd party pickup may not allowed,(ie. Farmers market)"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12">
        <span class="fs-14">
          You will be selling your products
          <b>From</b> to <b>To</b> timeframe. This is not the cooking time
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mr-5" cols="12" sm="9" md="3">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="schedule.date"
              label="Schedule Date"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="schedule.date"
            :reactive="true"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="mr-5" cols="12" sm="4" md="3" lg="1">
        <v-text-field v-model="schedule.fromHour" label="From"></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="1">
        <v-text-field v-model="schedule.toHour" label="To"></v-text-field>
      </v-col>
    </v-row>

    <!-- this is the location search bar -->
    <v-row align="center" v-if="!isRestaurant(profile)">
      <v-col class="mr-5" cols="12" sm="9" md="3">
        <v-combobox
          :items="addresses"
          item-text="name"
          prepend-icon="place"
          label="Name of location"
          placeholder="i.e. UCI Farmers Market"
          v-model="addressName"
          hide-details
          hide-no-data
          hide-selected
          return-object
          @change="setAddress()"
        ></v-combobox>
      </v-col>

      <v-col class="mr-5" cols="12" sm="4">
        <v-input
          hide-details
          single-line
          placeholder="Set Location"
          prepend-icon="my_location"
        >
          <vue-google-autocomplete
            id="map"
            ref="selectCity"
            focus="mapEvent"
            blur="mapEvent"
            inputChange="mapEvent"
            change="mapEvent"
            :placeholder="
              schedule.location_address
                ? schedule.location_address
                : 'Address of the location'
            "
            v-on:placechanged="setCity"
          ></vue-google-autocomplete>
        </v-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="mr-2" cols="12" md="6">
        <v-combobox
          :label="$t('Common.select_food_title')"
          :items="dishList"
          prepend-icon="restaurant"
          item-text="name"
          item-value="id"
          v-model="list_for_combo"
          multiple
          small-chips
          tags
          :delimiters="[',', ';']"
          persistent-hint
        >
          <template slot="selection" slot-scope="data">
            <v-chip
              :key="JSON.stringify(data.item.name)"
              :selected="data.selected"
              :disabled="data.disabled"
              class="v-chip--select-multi"
              @input="data.parent.selectItem(data.item.name)"
            >
              <v-avatar
                class="info white--text"
                v-text="data.item.name.slice(0, 1).toUpperCase()"
              ></v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="3" lg="2">
        <v-select
          :items="menus"
          item-text="name"
          item-value="id"
          label="From Menu"
          @change="selectMenu"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          v-bind:headers="headers"
          v-bind:items="schedule.products"
          v-bind:search="search"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="`schedule.products-${index}`"
              >
                <td>
                  <router-link
                    :to="`/product/edit/${item.chef.id}/${item.id}`"
                    >{{ item.name }}</router-link
                  >
                </td>
                <td>
                  <template v-if="dishImage(item)">
                    <img
                      :src="dishImage(item)"
                      class="img-responsive"
                      height="100"
                      width="100"
                    />
                  </template>
                  <template v-else>No Image</template>
                </td>

                <td>{{ item.price | currency }}</td>
                <td>
                  <v-edit-dialog
                    @open="tmp = getAmount(item.amountLeft)"
                    @save="saveMountLeft(item.id, tmp)"
                    large
                    lazy
                  >
                    <div>{{ getAmount(item.amountLeft) }}</div>
                    <div slot="input" class="mt-3 title">Update Amount</div>
                    <v-text-field
                      slot="input"
                      label="Edit"
                      v-model="tmp"
                      single-line
                      autofocus
                      :rules="[positive]"
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>{{ item.leadTime }}</td>
                <td>{{ item.orders ? item.orders.length : null }}</td>
                <td>
                  <v-icon color="red" @click="removeFromProducts(item.id)"
                    >delete</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" sm="8" md="2"></v-col>
      <v-btn color="success" @click="save">Save</v-btn>
    </v-row>
    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      heading="Deleting Schedule"
      message="Are you sure you want to delete this schedule?"
      @onConfirm="onDeleteSchedule"
    ></delete-confirmation-dialog>

    <!-- dialog for when schedule is active -->
    <v-dialog v-model="activeScheduleDialog" width="700px">
      <v-card>
        <v-card-title>
          <v-icon class="mb-2 mr-3" color="grey">block</v-icon>
          <h2>Can Not Delete Active Schedule</h2>
        </v-card-title>
        <h4 class="px-4 mb-4">
          Please set schedule to inactive before deleting.
        </h4>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="activeScheduleDialog = false"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { mapGetters } from 'vuex'
import { i18n } from 'fod-core'
import { profileMixin } from 'fod-core'

export default {
  props: ['type', 'user'],
  mixins: [profileMixin],
  data() {
    return {
      searchText: '',
      tmp: '',
      search: '',
      menu_list: [],
      list_for_combo: [],
      menu: false,
      pagination: {},
      positive: v =>
        parseFloat(v) >= 0 || v == 'unlimited' || 'Must be a positive number!',
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        { text: '', sortable: false, value: '' },
        { text: i18n.t('Common.price'), value: 'price' },
        { text: i18n.t('Chef.amount_left'), value: 'amount_left' },

        { text: i18n.t('Chef.lead_time'), value: 'lead_time' },

        { text: i18n.t('Common.active_orders'), value: 'orders' },
        { text: i18n.t('Common.delete'), value: '-' }
      ],
      activeScheduleDialog: false,
      addresses: [],
      addressName: null
    }
  },
  components: {
    VueGoogleAutocomplete
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      schedule: 'schedule',
      dishList: 'dishList',
      profile: 'profile',
      location: 'userLocation',
      searchLocation: 'searchLocation'
    }),
    title() {
      return `Search ${this.type}`
    },

    menus() {
      let list = []
      // un empty list
      if (this.menu_list) {
        this.menu_list.forEach(menu => {
          if (menu.items && menu.items.length > 0) {
            list.push(menu)
          }
        })
      }
      return list
      //return this.profile.menus ? Object.keys(this.profile.menus) : []
    }
  },
  mounted() {
    this.$store.dispatch('getSchedule', this.$route.params.id)

    this.$store.dispatch('loadDishList', { force: true })
    if (!this.location && !this.searchLocation) {
      console.log('Setting focus on selectCity')
      if (this.$refs.selectCity) {
        this.$refs.selectCity.focus()
      }
    }
    this.loadMenus()

    // get addresses for auto populate
    if (this.profile.addresses) {
      this.profile.addresses.forEach(address => {
        if (address.formatted_address) {
          this.addresses.push({
            name: address.name,
            address: address.formatted_address
          })
        }
      })
    }
    this.addressName = this.schedule.location_name
  },
  watch: {
    list_for_combo: function() {
      this.schedule.products = this.list_for_combo
    }
  },
  methods: {
    loadMenus() {
      this.$store.dispatch('loadMenus').then(list => {
        this.menu_list = list.filter(menu => menu.type != 'parent')
      })
    },
    setCity: function(addressData, placeResultData) {
      if (addressData) {
        this.schedule.location_address = placeResultData
          ? placeResultData.formatted_address
          : null
      }
    },

    mapEvent(event) {
      console.log('Map Event', event)
    },
    setShowLocation() {
      this.flagSetLocation = true
    },

    dishImage(dish) {
      return dish && dish.photos && dish.photos.length > 0
        ? dish.photos[0].url || dish.photos[0].imageUrl
        : null
    },
    save() {
      this.$store.dispatch('saveSchedule', this.schedule).then(() => {
        console.log('Saved')
        this.$store.commit('setMessage', {
          title: 'Schedule Saved',
          body: 'Schedule successfully saved'
        })
      })
    },
    activeStatus() {
      if (!this.schedule.isActive) {
        this.schedule.isPublic = false
      }
    },
    deleteSchedule() {
      // checks if schedule is empty first
      if (this.schedule.isActive) {
        this.activeScheduleDialog = true
      } else {
        this.$refs.deleteConfirmationDialog.openDialog()
      }
    },
    onDeleteSchedule() {
      this.$refs.deleteConfirmationDialog.close()
      this.$store.dispatch('deleteSchedule', this.schedule).then(() => {
        this.$router.push('/schedule/calendar')
      })
    },
    selectMenu(menu_id) {
      // check if already exists in list
      this.list_for_combo = []
      let list = this.schedule.products
        ? this.schedule.products.map(p => p.id)
        : []
      let menu = this.menu_list.filter(menu => menu.id === menu_id)[0]

      menu.items.forEach(item => {
        if (!list.includes(item.id)) {
          let d = this.dishList.filter(dish => dish.id === item)
          if (d && d.length > 0) {
            this.list_for_combo.push(d[0])
          }
        }
      })
    },
    removeFromProducts(id) {
      console.log('removing from schedule: ', id)
      this.schedule.products = this.schedule.products.filter(p => p.id != id)
    },
    getAmount(amount) {
      return amount == -1 ? 'unlimited' : amount
    },
    setAddress() {
      // check if user clicked on autocomplete option
      if (this.addressName.address) {
        this.schedule.location_address = this.addressName.address
        this.schedule.location_name = this.addressName.name
      } else {
        this.schedule.location_name = this.addressName
      }
    }
  }
}
</script>
<style scoped>
#map .v-input__prepend-outer,
#map .v-input__append-outer,
#map .v-input__slot,
#map .v-messages {
  border: 1px dashed rgba(255, 0, 0, 0.4);
}
.v-input input {
  width: 100%;
}
</style>
